<template>
  <div style="margin-bottom: 300px">
    <v-row class="text-center mt-2">
      <v-col cols="12" v-if="isOpen && !quotaLeft">
        <v-card
          class="mx-auto d-flex align-center justify-center pa-8"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-img
                width="200px"
                height="200px"
                src="../assets/logo/Logo1.jpg"
                alt="logoDragon"
                class="mx-auto"
                style="max-width: 100%"
              />
            </v-col>
            <v-col cols="12" class="mt-n5">
              <span
                :style="
                  $vuetify.breakpoint.smAndUp
                    ? 'font-size: 20px; color: #008080;'
                    : 'font-size: 16px; color: #008080;'
                "
              >
                <v-icon color="#008080" size="30">mdi-magnify</v-icon>
                {{ $t("message.lotterysearch") }}
              </span>
            </v-col>
            <v-col cols="12" class="mt-n5">
              <span
                style="color: #718096"
                class="text-h6 text-md-h5 text-sm-h6"
              >
                {{ $t("message.typelotterysearch") }}
              </span>
            </v-col>

            <v-col
              v-for="(value, key, index) in form"
              :key="key"
              cols="2"
              md="2"
              sm="2"
              style="padding: 1px"
            >
              <v-text-field
                v-model="form[key]"
                dense
                outlined
                hide-details="auto"
                style="width: 100%; padding: 0 0px; font-size: 24px"
                class="centered-input custom-input"
                maxlength="1"
                @focus="clearField(key)"
                @input="handleInput(key, index)"
                ref="textInputFields"
                type="tel"
                pattern="\d*"
                oninput="this.value = this.value.replace(/\D/g, '')"
                inputmode="numeric"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn :color="'#008080'" @click="handleSearchBtn()" block>
                <span style="font-weight: bold; color: #fff">
                  {{ $t("message.search") }}
                </span>
              </v-btn>
              <br />
              <v-btn
                class="mt-n7"
                :class="isClickedDelAll ? '' : 'delAll-button'"
                :text="isClickedDelAll ? text : ''"
                :color="isClickedDelAll ? 'rgb(219, 9, 52)' : ''"
                @click="handleDelAllBtn"
                small
                ><span
                  :style="
                    isClickedDelAll
                      ? 'color: white; font-weight: bold'
                      : 'font-weight: bold'
                  "
                >
                  {{ $t("message.delAll") }}
                </span>
              </v-btn>
            </v-col>

            <v-col cols="12" class="mt-n5">
              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(1)"
                :class="{ 'clicked-btn': clickedBtn === 1 }"
                :text="isTextMode(1)"
                class="ma-1"
              >
                <span style="font-weight: bold">
                  {{ $t("message.seeAll") }}
                </span>
              </v-chip>

              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(2)"
                :class="{ 'clicked-btn': clickedBtn === 2 }"
                :text="isTextMode(2)"
                class="ma-1"
              >
                <span style="font-weight: bold">
                  {{ $t("message.1Ticket") }}
                </span>
              </v-chip>

              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(3)"
                :class="{
                  'clicked-btn': clickedBtn === 3,
                }"
                :text="isTextMode(3)"
                class="ma-1"
              >
                <span style="font-weight: bold">
                  {{ $t("message.bigset") }}
                </span>
              </v-chip>

              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(4)"
                :class="{
                  'clicked-btn': clickedBtn === 4,
                }"
                :text="isTextMode(4)"
                class="ma-1"
              >
                <span style="font-weight: bold">
                  {{ "ชุด5ใบ" }}
                </span>
              </v-chip>
            </v-col>
            <!-- <v-col cols="12" class="mt-n5">
              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(4)"
                :class="{
                  'clicked-btn': clickedBtn === 4,
                  'mt-3': $vuetify.breakpoint.width < 465,
                }"
                :text="isTextMode(4)"
                class="ma-1"
              >
                <span style="font-weight: bold">
                  {{ $t("message.3Ticket") }}
                </span>
              </v-chip>

              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(5)"
                :class="{
                  'clicked-btn': clickedBtn === 5,
                  'mt-3': $vuetify.breakpoint.width < 520,
                }"
                :text="isTextMode(5)"
                class="ma-1"
              >
                <span style="font-weight: bold">
                  {{ $t("message.4Ticket") }}
                </span>
              </v-chip>

              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(6)"
                :class="{
                  'clicked-btn': clickedBtn === 6,
                  'mt-3': $vuetify.breakpoint.width < 465,
                }"
                :text="isTextMode(6)"
                class="ma-1"
              >
                <span style="font-weight: bold">
                  {{ $t("message.5Ticket") }}
                </span>
              </v-chip>
            </v-col> -->

            <v-col cols="12">
              <!-- &nbsp;
              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(7)"
                :class="{ 'clicked-btn': clickedBtn === 7 }"
                :text="isTextMode(7)"
              >
                <span style="font-weight: bold">
                  {{ $t("message.random") }}
                </span>
              </v-chip>
              &nbsp;
              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(8)"
                :class="{
                  'clicked-btn': clickedBtn === 8,
                  'mt-3':
                    $vuetify.breakpoint.width < 410 && $i18n.locale == 'en',
                }"
                :text="isTextMode(8)"
              >
                <span style="font-weight: bold">
                  {{ $t("message.bigset") }}
                </span>
              </v-chip>
              &nbsp;
              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(9)"
                :class="{
                  'clicked-btn': clickedBtn === 9,
                  'mt-3': $vuetify.breakpoint.width < 500,
                }"
                :text="isTextMode(9)"
              >
                <span style="font-weight: bold">
                  {{ $t("message.beautifulfront") }}
                </span>
              </v-chip>
              &nbsp;
              <v-chip
                outlined
                color="#4ca3ad"
                @click="handleButtonClick(10)"
                :class="{
                  'clicked-btn': clickedBtn === 10,
                  'mt-3':
                    $vuetify.breakpoint.width < 600 || $i18n.locale == 'en',
                }"
                :text="isTextMode(10)"
              >
                <span style="font-weight: bold">
                  {{ $t("message.popnumber") }}
                </span>
              </v-chip> -->
            </v-col>
          </v-row>
        </v-card>

        <v-row
          class="ma-2"
          v-if="itemsLottery.length && undefineLottery == false"
        >
          <v-col
            cols="6"
            md="3"
            lg="2"
            sm="4"
            v-for="(item, index) in itemsLottery"
            :key="index"
            style="padding: 4px"
          >
            <div class="mt-n14">
              <v-card
                class="card-info-prize mt-4"
                :style="{ top: $vuetify.breakpoint.smAndUp ? '60px' : '50px' }"
                @click="handleCardClick(index)"
                :class="{ 'clicked-card': clickedCardIndices.includes(index) }"
                :disabled="loading"
              >
                <span
                  :style="
                    $vuetify.breakpoint.smAndUp
                      ? 'font-size: 16px;'
                      : 'font-size: 12px;'
                  "
                >
                  {{ item.setAmt }} {{ $t("message.ticket") }} <br />
                  {{ item.setAmt * 6 }} {{ $t("message.million") }}
                </span>
              </v-card>
              <v-card
                style="border-radius: 10px !important"
                class="pa-1"
                @click="handleCardClick(index)"
                :class="{ 'clicked-card': clickedCardIndices.includes(index) }"
                :disabled="loading"
              >
                <v-img :src="item.urlImage"></v-img>
                <div class="info2">
                  <v-img class="mx-auto mb-0" src="@/assets/logo/logoCard.png">
                  </v-img>
                </div>
                <v-btn color="#718096" block x-small
                  ><span
                    :class="{
                      'clicked-icon': clickedCardIndices.includes(index),
                      'black-text': clickedCardIndices.includes(index),
                      'white-text': !clickedCardIndices.includes(index),
                    }"
                    >เพิ่มลงตะกร้า</span
                  >
                </v-btn>
                <v-icon
                  v-if="clickedCardIndices.includes(index)"
                  class="icon-center"
                  color="#006e6e"
                  size="60px"
                  >mdi-basket-check</v-icon
                >
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <!-- <v-col cols="12">
            <v-chip class="ma-2" color="#2e2e2e" label outlined>
              <span
                :style="
                  $vuetify.breakpoint.smAndUp
                    ? 'font-size: 16px;'
                    : 'font-size: 12px;'
                "
              >
                ไม่พบสิ่งที่ท่านต้องการค้นหา
              </span>
            </v-chip>
          </v-col> -->
          <v-card
            class="mx-auto d-flex align-center justify-center pa-8"
            style="max-width: 700px; height: auto; width: 95%"
            v-if="!itemsLottery.length && undefineLottery == false"
          >
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-img
                  width="200px"
                  height="160px"
                  src="../assets/logo/Logo1.jpg"
                  alt="logoDragon"
                  class="mx-auto"
                  style="max-width: 100%"
                />
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <span style="color: black; font-size: 30px">
                  กำลังโหลดข้อมูล </span
                ><br />
                <span style="color: black; font-size: 20px">
                  กรุณารอซักครู่
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            class="mx-auto d-flex align-center justify-center pa-8"
            style="max-width: 700px; height: auto; width: 95%"
            v-if="undefineLottery == true"
          >
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-img
                  width="200px"
                  height="160px"
                  src="../assets/logo/Logo1.jpg"
                  alt="logoDragon"
                  class="mx-auto"
                  style="max-width: 100%"
                />
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <span style="color: red; font-size: 30px"> ขออภัย </span><br />
                <span style="color: red; font-size: 20px">
                  ไม่พบหมายเลขที่คุณค้นหา <br /><br />
                  หรือหมายเลขที่ค้นหาหมดแล้ว
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-row>

        <div class="sticky">
          <v-fab-transition>
            <v-btn
              fab
              large
              dark
              bottom
              left
              class="v-btn--example"
              color="#008080"
              @click="goToBasket()"
            >
              <v-icon>mdi-basket</v-icon>
              <span v-if="selectedItems.length" class="badge">{{
                selectedItems.length
              }}</span>
              <span v-if="selectedItems.length" class="badge-timer">{{
                formatTime(countdown)
              }}</span>
            </v-btn>
          </v-fab-transition>
        </div>
      </v-col>

      <v-col cols="12" v-if="!isOpen">
        <v-card
          class="mx-auto d-flex align-center justify-center pa-8"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-img
                width="200px"
                height="200px"
                src="../assets/logo/Logo1.jpg"
                alt="logoDragon"
                class="mx-auto"
                style="max-width: 100%"
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <span style="color: red; font-size: 24px">
                ปิดระบบการขาย 14:00 น. เรียบร้อยแล้ว <br />
                <br />
                พบกันใหม่งวดถัดไป <br />
                <br />
                เปิดขายทุกวันที่ 6 และ 21 ของทุกเดือน <br />
                <br />
                แอดไลน์ไว้รับข่าวสารได้เลย!
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="quotaLeft">
        <v-card
          class="mx-auto d-flex align-center justify-center pa-8"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-img
                width="200px"
                height="200px"
                src="../assets/logo/Logo1.jpg"
                alt="logoDragon"
                class="mx-auto"
                style="max-width: 100%"
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <span style="color: red; font-size: 30px"> ขออภัย! </span><br />
              <span style="color: red; font-size: 20px">
                จำหน่ายลอตเตอรี่หมดแล้ว <br /><br />
                พบกันใหม่งวดถัดไป <br /><br />
                เปิดขายทุกวันที่ 6 และ 21 ของทุกเดือน <br /><br />
                แอดไลน์ไว้รับข่าวสารได้เลย!
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="itemsLottery.length">
        <v-btn @click="clickGetAll()" :loading="loading">
          ดูลอตเตอรี่เพิ่ม คลิกที่นี่
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="loading"> กำลังโหลด กรุณารอซักครู่ </v-col>
    </v-row>

    <DialogLogin :dialog="dialogLogin" @closeDialogLogin="closeDialogLogin" />
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import DialogLogin from "./Dialog/DialogLogin.vue";
import prizeTableCheck from "./prizeTableCheck.vue";

export default {
  components: {
    prizeTableCheck,
    DialogLogin,
  },

  name: "BuyLottery",

  data: () => ({
    loading: false,
    lastScrollTop: 0,
    isOpen: true,
    quotaLeft: false,
    countdown: 0,
    timerInterval: null,

    dialogLogin: false,
    clickedCardIndices: [],
    selectedItems: [],
    clickedBtn: null,
    isClickedDelAll: false,
    selectedTab: 1,

    textMode: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
    },

    form: {
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      field6: "",
    },

    itemsLottery: [],
    itemsLotteryAll: [],

    setAmt: [],

    messageKey: "message.dragondigital_lottery_online",

    fab: false,
    hidden: false,
    tabs: null,

    userData: [],
    checkSet: 1,

    undefineLottery: false,
  }),

  watch: {},

  computed: {
    formatText() {
      const rawText = this.$t(this.messageKey);
      // Replace \n with HTML line breaks
      return rawText.replace(/\n/g, "<br>");
    },
  },

  async created() {
    const encodedUserLogin = localStorage.getItem("KodsetthiUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }

    this.selectedItems = [];
    await this.getAll();
    const storedItems = localStorage.getItem("selectedItems");
    this.selectedItems = storedItems ? JSON.parse(storedItems) : [];

    await this.checkCardItem();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.initializeCountdown();
    this.startCountdown();

    this.textInputFields = Object.values(this.$refs.textInputFields);

    if (this.countdown == 0) {
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("storedDatetime");
    }
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    this.stopCountdown();
  },

  methods: {
    async clickGetAll() {
      try {
        this.loading = true;
        await this.getMoreLottery();
        this.loading = false;
      } catch (error) {
        console.error("Error:", error);
        this.loading = false;
      }
    },
    handleScroll() {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const scrollPosition = window.scrollY;

      const isAtBottom = scrollPosition + windowHeight >= documentHeight;

      if (isAtBottom) {
        const currentScrollPosition = window.scrollY;
        this.getMoreLottery().then(() => {
          window.scrollTo(0, currentScrollPosition);
        });
      }
    },
    startCountdown() {
      this.timerInterval = setInterval(async () => {
        if (this.countdown > 0) {
          this.countdown--;
          if (this.countdown === 1) {
            await this.$swal.fire({
              icon: "warning",
              html: "หมดเวลาตะกร้าสินค้า",
              showConfirmButton: true,
            });
            localStorage.removeItem("selectedItems");
            localStorage.removeItem("storedDatetime");
            this.$router.go(0);
          }
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    async stopCountdown() {
      clearInterval(this.timerInterval);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    },
    initializeCountdown() {
      const storedDatetime = localStorage.getItem("storedDatetime");
      if (storedDatetime) {
        const storedTime = new Date(storedDatetime).getTime();
        const currentTime = new Date().getTime();
        const differenceInSeconds = Math.floor(
          (currentTime - storedTime) / 1000
        );
        this.countdown = Math.max(900 - differenceInSeconds, 0);
      } else {
        this.countdown = 0;
      }
    },

    closeDialogLogin() {
      this.dialogLogin = false;
    },
    goToBasket() {
      if (Object.keys(this.userData).length !== 0) {
        this.$router.currentRoute.path == "/basket"
          ? ""
          : this.$router.push({
              path: "/basket",
            });
      } else {
        this.dialogLogin = true;
      }
    },
    checkUndefinedLottery(val) {
      if (!val.length) {
        this.undefineLottery = true;
      } else {
        this.undefineLottery = false;
      }
    },

    async getAll() {
      this.loading = true;
      const agentId = 18;
      const auth = {
        headers: { Authorization: `Bearer ${this.userData.token}` },
      };
      const responseQuota = await this.axios.get(
        `${process.env.VUE_APP_API}/lottery/getReportQuota${
          agentId ? "?agentId=" + agentId : ""
        }`,
        auth
      );

      let reportQuotaData = responseQuota.data.data[0];
      if (reportQuotaData.quotaLeft <= 0) {
        // แก้ให้ซื้อได้ไปก่อน
        this.quotaLeft = true;
      } else {
        this.quotaLeft = false;

        const data = {
          position1: this.form.field1,
          position2: this.form.field2,
          position3: this.form.field3,
          position4: this.form.field4,
          position5: this.form.field5,
          position6: this.form.field6,
          setAmt: this.setAmt,
          stock: 1,
          agentId: 18,
        };

        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/lottery/searchByNumber`,
          data
        );

        const IS_OPEN = await this.axios.get(
          `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_MAIN`
        );

        if (IS_OPEN.data.data.value1 == 0) {
          this.isOpen = false;
        } else {
          this.isOpen = true;
        }

        const IS_OPEN_ADMIN = await this.axios.get(
          `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_ADMIN`
        );

        if (IS_OPEN_ADMIN.data.data.value1 == 1) {
          if (this.userData.length) {
            if (this.userData.user.userType == "ADMIN") {
              this.isOpen = true;
            }
          }
        }

        const IS_NEW_LOGIN = await this.axios.get(
          `${process.env.VUE_APP_API}/configs/findByType/IS_NEW_LOGIN`
        );
        if (IS_NEW_LOGIN.data.data.value1 == 1) {
          const encodedLogout = localStorage.getItem("alreadyLogout");
          if (encodedLogout !== null) {
            const decodedLotout = Decode.decode(encodedLogout);
            const alreadyLogoutCheck = JSON.parse(decodedLotout);
            if (alreadyLogoutCheck.alreadyLogout == true) {
            }
          } else {
            const alreadyLogout = { alreadyLogout: true };
            localStorage.removeItem("KodsetthiUser");
            localStorage.clear();
            this.$router.currentRoute.path == "/"
              ? this.$router.go(0)
              : this.$router.push({
                  path: "/",
                });
            localStorage.setItem("alreadyLogout", Encode.encode(alreadyLogout));
          }
        }

        this.itemsLottery = response.data.data;
        this.itemsLotteryAll = response.data.data;
        this.clickedBtn = 1;
        this.checkUndefinedLottery(this.itemsLottery);
      }
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.loading = false;
    },
    async searchLottery() {
      if (Object.keys(this.userData).length !== 0) {
        const data = {
          position1: this.form.field1,
          position2: this.form.field2,
          position3: this.form.field3,
          position4: this.form.field4,
          position5: this.form.field5,
          position6: this.form.field6,
          setAmt: this.setAmt,
          stock: 1,
          agentId: 18,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/lottery/searchByNumber`,
          data
        );

        this.itemsLottery = response.data.data;
        this.itemsLotteryAll = response.data.data;
        this.clickedBtn = 1;
        this.checkUndefinedLottery(this.itemsLottery);

        this.checkCardItem();
      } else {
        this.dialogLogin = true;
      }
    },
    async getMoreLottery() {
      this.loading = true;
      // if (Object.keys(this.userData).length !== 0) {
      const data = {
        position1: this.form.field1,
        position2: this.form.field2,
        position3: this.form.field3,
        position4: this.form.field4,
        position5: this.form.field5,
        position6: this.form.field6,
        setAmt:
          this.checkSet == 1
            ? []
            : this.checkSet == 2
            ? [1]
            : this.checkSet == 3
            ? []
            : this.checkSet == 4
            ? [5]
            : "",
        stock: 1,
        agentId: 18,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/lottery/searchByNumber`,
        data
      );
      const filteredItems = response.data.data.filter(
        (item) =>
          !this.itemsLottery.some((existingItem) => existingItem.id === item.id)
      );

      if (this.checkSet == 1 || this.checkSet == 2 || this.checkSet == 4) {
        this.itemsLottery.push(...filteredItems);
      } else if (this.checkSet == 3) {
        let filterMore1 = filteredItems.filter((x) => x.setAmt > 1);
        this.itemsLottery.push(...filterMore1);
      }
      this.checkCardItem();
      // }
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.loading = false;
    },
    isTextMode(btnNumber) {
      return this.clickedBtn === btnNumber ? this.textMode[btnNumber] : false;
    },
    handleButtonClick(btnNumber) {
      this.clickedBtn = btnNumber;

      if (btnNumber == 1) {
        this.setAmt = [];
        this.checkSet = 1;
        this.itemsLottery = this.itemsLotteryAll;
        this.checkCardItem();
      }
      if (btnNumber == 2) {
        this.itemsLottery = this.itemsLotteryAll.filter((x) => x.setAmt == 1);
        this.checkSet = 2;
        this.getMoreLottery();
        this.checkCardItem();
      }
      if (btnNumber == 3) {
        this.itemsLottery = this.itemsLotteryAll.filter((x) => x.setAmt > 1);
        this.checkSet = 3;
        this.getMoreLottery();
        this.checkCardItem();
      }
      if (btnNumber == 4) {
        this.itemsLottery = this.itemsLotteryAll.filter((x) => x.setAmt == 5);
        this.checkSet = 4;
        this.getMoreLottery();
        this.checkCardItem();
      }
      // if (btnNumber == 5) {
      //   // this.setAmt = 4;
      //   this.itemsLottery = this.itemsLotteryAll.filter((x) => x.setAmt == 4);
      // }
      // if (btnNumber == 6) {
      //   // this.setAmt = 5;
      //   this.itemsLottery = this.itemsLotteryAll.filter((x) => x.setAmt == 5);
      // }
      // if (btnNumber == 8) {
      //   // this.setAmt = [];
      //   this.itemsLottery = this.itemsLotteryAll.filter((x) => x.setAmt >= 6);
      // }
    },
    handleDelAllBtn() {
      this.isClickedDelAll = true;
      this.form = {
        field1: "",
        field2: "",
        field3: "",
        field4: "",
        field5: "",
        field6: "",
      };
    },
    async handleSearchBtn() {
      this.isClickedDelAll = false;
      this.clickedBtn = 1;

      this.searchLottery();
    },
    clearField(key) {
      this.form[key] = "";
    },
    handleInput(key, index) {
      const value = this.form[key];
      if (this.form[key] < 0) {
        this.form[key] = Math.abs(this.form[key]);
      }
      if (value.length === 1) {
        this.focusNextField(index);
      }
    },
    focusNextField(currentIndex) {
      const nextIndex = currentIndex + 1;

      if (nextIndex < this.textInputFields.length) {
        this.textInputFields[nextIndex].focus();
      }
      if (nextIndex === 6) {
        this.textInputFields.forEach((inputField) => {
          if (inputField) {
            inputField.blur();
          }
        });
      }
    },

    async checkCardItem() {
      this.clickedCardIndices = [];

      this.selectedItems.forEach((element) => {
        let test = this.itemsLottery.filter((x) => x.id == element.id);
        let indexInItemsLottery = this.itemsLottery.indexOf(test[0]);

        const selectedIndex =
          this.clickedCardIndices.indexOf(indexInItemsLottery);
        if (selectedIndex) {
          this.$set(
            this.clickedCardIndices,
            this.clickedCardIndices.length,
            indexInItemsLottery
          );
        }
      });
    },
    async handleCardClick(index) {
      this.loading = true;
      if (Object.keys(this.userData).length !== 0) {
        const selectedIndex = this.clickedCardIndices.indexOf(index);
        if (selectedIndex !== -1) {
          this.$delete(this.clickedCardIndices, selectedIndex);
          const selectedItemIndex = this.selectedItems.findIndex(
            (item) => item.id === this.itemsLottery[index].id
          );
          if (selectedItemIndex !== -1) {
            this.$delete(this.selectedItems, selectedItemIndex);
          }
        } else {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/lottery/checkStock?lotteryNo=${this.itemsLottery[index].lotteryNo}`
          );

          if (response.data.data == true) {
            this.$set(
              this.clickedCardIndices,
              this.clickedCardIndices.length,
              index
            );
            this.selectedItems.push(this.itemsLottery[index]);
          } else {
            await this.$swal.fire({
              icon: "warning",
              html: "ล็อตเตอรี่ใบนี้ถูกจองไปแล้ว",
              showConfirmButton: true,
              timer: 1500,
            });
            // await this.itemsLottery.splice(index, 1);
            this.$router.go(0);
          }
        }
        localStorage.setItem(
          "selectedItems",
          JSON.stringify(this.selectedItems)
        );
        setTimeout(() => {
          this.loading = false;
        }, 500);

        if (!this.selectedItems.length) {
          this.stopCountdown();
          localStorage.removeItem("selectedItems");
          localStorage.removeItem("storedDatetime");
        }
        if (
          this.selectedItems.length > 0 &&
          !localStorage.getItem("storedDatetime")
        ) {
          const currentTime = new Date().toISOString();
          localStorage.setItem("storedDatetime", currentTime);

          this.initializeCountdown();
          this.startCountdown();
        }
      } else {
        this.dialogLogin = true;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-text-field--filled .v-input__control .v-input__slot,
::v-deep .v-text-field--outlined .v-input__control .v-input__slot {
  min-height: 70px !important; /* Set the desired height here */
}

.badge {
  position: absolute;
  top: -16px;
  right: 40px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px;
  font-size: 12px;
  width: 20px; /* Set a fixed width to ensure a circular shape */
  height: 20px; /* Set a fixed height to ensure a circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge-timer {
  position: absolute;
  top: 25px;
  right: 22px;
  color: white;
  border-radius: 0%;
  padding: 4px;
  font-size: 12px;
  width: 20px; /* Set a fixed width to ensure a circular shape */
  height: 20px; /* Set a fixed height to ensure a circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
}

.clicked-card {
  background-color: #008080;
  opacity: 0.8;
}

.black-text {
  color: #000000;
}
.white-text {
  color: #ffffff;
}

.icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px; /* Adjust the font size as needed */
}

::v-deep .centered-input input {
  text-align: center;
}

.text-h5,
.text-md-h4,
.text-sm-h5,
.text-h6,
.text-md-h5,
.text-sm-h6 {
  font-family: var(--font-family) !important;
}

.card-info-prize {
  border-radius: 5px 5px 25px 25px !important;
  /* border: 1px solid #eac13c; */
  z-index: 2;
  -webkit-box-pack: center;
  justify-content: center;
  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
  width: 25%;
  height: 35%;
  max-height: 80px;
  display: flex;
  padding: 4px;

  left: 2px;
  color: black;
}

.clicked-btn {
  background-color: #4ca3ad; /* Add your desired background color here */
  color: #2e2e2e !important; /* Add your desired text color here */
}

.delAll-button {
  border: 2px solid;
  border-color: rgb(219, 9, 52);
  color: rgb(219, 9, 52);
}
.search-button {
  border: 2px solid;
  border-color: #4ca3ad;
  color: #4ca3ad;
}

.custom-input {
  font-size: 4vh; /* Default font size for larger screens */
  font-weight: bold;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: fixed;
  top: 80%;
  left: 45%;
  z-index: 1000;
}

.info2 {
  height: 150px;
  margin-top: -50%;
  /* margin-bottom: 15px; */
  margin-left: 50%;
  margin-right: 0%;
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 5%;
  max-height: 10%;
  /* background: #f3e0bf73; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
}

@media only screen and (min-width: 600px) {
  .sticky {
    top: 80%;
    left: 45%;
  }
}
/* Styles for screens smaller than sm */
@media only screen and (max-width: 599px) {
  .sticky {
    top: 82%;
    left: 40%;
  }
}

@media only screen and (max-width: 1300px) {
  .info2 {
    height: 130px;
    margin-top: -50%;
    /* margin-bottom: 15px; */
    margin-left: 50%;
    margin-right: 0%;
    position: relative;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 5%;
    max-height: 10%;
    /* background: #f3e0bf73; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 960px) {
  .info2 {
    height: 140px;
    margin-top: -50%;
    /* margin-bottom: 15px; */
    margin-left: 50%;
    margin-right: 0%;
    position: relative;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 5%;
    max-height: 10%;
    /* background: #f3e0bf73; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 800px) {
  .info2 {
    height: 100px;
    margin-top: -50%;
    /* margin-bottom: 15px; */
    margin-left: 50%;
    margin-right: 0%;
    position: relative;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 5%;
    max-height: 10%;
    /* background: #f3e0bf73; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
  }
}

@media screen and (max-width: 600px) {
  .custom-input {
    font-size: 2vh; /* Default font size for larger screens */
    font-weight: bold;
  }

  .info2 {
    height: 130px;
    margin-top: -50%;
    /* margin-bottom: 15px; */
    margin-left: 50%;
    margin-right: 0%;
    position: relative;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 5%;
    max-height: 10%;
    /* background: #f3e0bf73; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
  }
}
@media screen and (max-width: 500px) {
  .custom-input {
    font-size: 2vh; /* Default font size for larger screens */
    font-weight: bold;
  }

  .info2 {
    height: 100px;
    margin-top: -50%;
    /* margin-bottom: 15px; */
    margin-left: 50%;
    margin-right: 0%;
    position: relative;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 5%;
    max-height: 10%;
    /* background: #f3e0bf73; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
  }
}
@media screen and (max-width: 400px) {
  .custom-input {
    font-size: 1.5vh; /* Default font size for larger screens */
    font-weight: bold;
  }

  .info2 {
    height: 80px;
    margin-top: -50%;
    /* margin-bottom: 15px; */
    margin-left: 50%;
    margin-right: 0%;
    position: relative;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 5%;
    max-height: 10%;
    /* background: #f3e0bf73; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
  }
}
</style>

